export default [
  {
    title: 'Tableau de bord',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  // product
  {
    title: 'Produits',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Liste standart',
        route: 'products',
      },
      {
        title: 'Liste sur mesure',
        route: 'products-on-measure',
      }, 
      {
        title: 'Ajout',
        route: 'create-product',
      },
    ],
  },
  {
    title: 'Clients',
    icon: 'UserCheckIcon',
    children: [
      {
        title: 'Liste',
        route: 'customers',
      },
      {
        title: 'Ajout',
        route: 'create-customer',
      },
    ],
  },
  {
    title: 'Commande Achat',
    icon: 'ShoppingBagIcon',
    children: [
      {
        title: 'Liste',
        route: 'purchase-orders',
      },
      {
        title: 'Ajout',
        route: 'create-purchase-order',
      },

    ],
  },
  {
    title: 'Commande Vente',
    icon: 'ShoppingBagIcon',
    children: [
      {
        title: 'Standart',
        icon: 'ShoppingBagIcon',
          children: [
            {
              title: 'Liste',
              route: 'orders',
            },
            {
              title: 'Ajout',
              route: 'create-order',
            }]},
      {
        title: 'Sur mesure',
        icon: 'ShoppingBagIcon',
        children: [
          {
            title: 'Liste',
            route: 'custom-orders',
          },
          {
            title: 'Ajout',
            route: 'create-custom-order',
          }],
      }, ]
  },

  {
    title: 'Bon de sortie',
    icon: 'TruckIcon',
    children: [
      {
        title: 'Liste',
        route: 'exit-vouchers',
      },

    ],
  },
  {
    title: 'Factures',
    icon: 'ClipboardIcon',
    children: [
      {
        title: 'Liste',
        route: 'invoices',
      },
      {
        title: 'Ajout',
        route: 'create-invoice',
      },
    ],
  },
  {
    title: 'Devis',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Liste',
        route: 'estimates',
      },
      {
        title: 'Ajout',
        route: 'create-estimate',
      },
    ],
  },

  {
    title: 'Paramètre',
    icon: 'SettingsIcon',
    route: 'settings',
  },
]
